// Google Mat Icons
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");//TODO deprecated, consider replacing
// TODO: Some reason this is fail.ing on prod. Will investigate further
// @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined");
// @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD,ROND@20..48,100..700,0..1,-50..200,0..100");

//Custom Fig Icon. TODO: Remove soon
@import url("https://www.figmarketing.com/files/fonts/fig-icon/4.5/style.css");

/* Rules for sizing the icon. */
@layer utilities {
    .material-icons {
        font-size: 2.4rem;
    }
    .material-icons.mi-14 {
        font-size: 1.4rem;
    }
    .material-icons.mi-18 {
        font-size: 1.8rem;
    }
    .material-icons.mi-24 {
        font-size: 2.4rem;
    }
    .material-icons.mi-36 {
        font-size: 3.6rem;
    }
    .material-icons.mi-48 {
        font-size: 4.8rem;
    }
}

@layer utilities {
    /*FIG icon*/
    .fig-18px::before {
        font-size: 18px;
    }

    .fig-24px::before {
        font-size: 24px;
    }

    .fig-36px::before {
        font-size: 36px;
    }

    .fig-48px::before {
        font-size: 48px;
    }

    .fig-96px::before {
        font-size: 96px;
    }

    .fig-128px::before {
        font-size: 128px;
    }
}
