@import "styles.angular-custom.scss";
@import "styles.typography.scss";
@import "styles.icons.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/*---------------------*/
/*NEW COMPONENT MIXINS*/
/*---------------------*/

@mixin transition-multi($duration: 0.5s, $timing: cubic-bezier(0.445, 0.05, 0.55, 0.95), $delay: 0s, $property...) {
    @if $timing == "cubic" {
        $timing: cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }
    /*@if $property == 'transform' {
        $property: '-o-transform', '-moz-transform', '-ms-transform', '-webkit-transform', 'transform';
    }*/
    -moz-transition: $duration $timing $delay;
    -moz-transition-property: $property;
    -o-transition: $duration $timing $delay;
    -o-transition-property: $property;
    -webkit-transition: $duration $timing $delay;
    -webkit-transition-property: $property;
    transition: $duration $timing $delay;
    transition-property: $property;
    /*@if $property == opacity {
        -webkit-transition: $duration $timing $delay nth($property, 1);
        transition: $duration $timing $delay nth($property, 1);
    }*/
}
//label text mixin. has some default label styling. extend with colors & text-utils mixins
@mixin label {
    &.label {
        @apply p-0;
        @apply font-medium;
        @apply text-sm;
        @apply text-gray;
        @apply leading-snug;
        @apply whitespace-normal;
        @apply text-left;
        @apply inline-block;

        &.is-xl {
            @apply text-lg;
        }

        &.is-lg {
            @apply text-base;
        }

        &.is-md {
            @apply text-sm;
        }

        &.is-sm {
            @apply text-xs;
        }

        &.is-required {
            @apply text-red;

            &::after {
                content: "*";
                @apply absolute text-red ml-5 top-5;
            }
        }
    }
}

//generic text mixin: has little default styling. can be extended with text & color utils
@mixin text {
    &.text {
        @apply text-base leading-normal font-medium;
    }
}

@mixin card {
    &.card {
        @apply bg-white dark:bg-gray-darkest border-solid dark:border-gray-darker dark:border dark:text-gray-lighter;
        @apply shadow dark:shadow-none;
        @apply rounded-lg;
    }
}

@layer components {
    .custom-table {
        @apply border-solid border-gray-lighter border-collapse table-auto w-full;
        td {
            @apply border-solid border-gray-lighter border p-10 h-58 text-sm;
        }
        thead {
            td {
                @apply border-t-0 hover:bg-gray-lighter bg-gray-lightest z-10 sticky top-0 text-xs text-gray font-bold uppercase;
            }
        }
        &.cpc-policy-table {
            tr.status-pending {
                td {
                    @apply bg-blue-lightest;
                }
            }
        }

        tbody {
            tr {
                &.is-warning {
                    td {
                        @apply bg-orange-lightest;
                    }

                    &:hover {
                        td {
                            @apply bg-orange-lighter bg-opacity-50;
                        }
                    }
                }

                &.has-analytics {
                    td {
                        @apply bg-green-lightest;
                    }

                    &:hover {
                        td {
                            @apply bg-green-lighter bg-opacity-75;
                        }
                    }
                }

                &:hover {
                    td {
                        @apply bg-blue-lightest;
                    }
                }
            }
        }

        tr {
            @apply transition duration-150;
        }

        td:first-child {
            @apply border-l-0 pl-20;
        }
        td:last-child {
            @apply border-r-0 pr-20;
        }
    }
}

@layer components {
    .custom-table-card {
        border-collapse: separate;
        border-spacing: 0 2rem;

        tr {
            @apply transition duration-150 shadow;
            td {
                @apply transition duration-150 bg-white px-10 py-15;
            }
        }

        td:first-child {
            @apply rounded-l pl-20;
        }
        td:last-child {
            @apply rounded-r pr-20;
        }
    }
}

@layer components {
    .divider {
        @apply bg-gray-lighter block dark:bg-gray-darker;

        &:not(.is-vertical) {
            @apply my-10 w-full h-px;
        }

        &.is-vertical {
            @apply mx-10 h-full w-px;
        }
    }
}

@layer components {
    .inline-snackbar {
        @apply block bg-blue-lightest border border-solid border-gray-lighter text-sm bg-opacity-75 rounded;
        &.is-success {
            @apply bg-green-lightest text-green border-green-lighter;
        }

        &.is-error {
            @apply bg-red-lightest text-red border-red-lighter;
        }
    }
}

@layer components {
    .portal-tour {
        .mat-mdc-card-title {
            @apply text-lg font-bold;
        }
        .mat-mdc-icon-button {
            mat-icon {
                line-height: 0.75;
            }
        }
        .mat-mdc-card-content {
            @apply text-lg;
        }

        .mat-mdc-card-actions {
            .mat-mdc-button {
                @apply text-sm uppercase font-bold;

                &:not([disabled]) {
                    @apply text-blue-dark hover:text-blue-900;
                }
            }
        }

        .mat-mdc-button {
            .mdc-button__label {
                @apply w-auto;
            }

            .mat-icon {
                font-size: 1.5rem !important;
                @apply w-15 h-15;
            }
        }
    }
}

html {
    @apply leading-normal lg:text-normalize text-normalize-laptop;
}

body {
    @apply font-body h-full relative font-medium overflow-hidden text-base text-gray-dark;

    * {
        @include card;
    }

    *:focus {
        outline: none;
    }

    //button mixins: button sizes, colors, states, etc. used for both text & icon buttons
    .icon-button,
    .icon-button.mat-mdc-button {
        @extend .button;
        @apply p-0 h-34 w-34 leading-none min-w-34 min-h-0 inline-flex items-center justify-center;
        line-height: 2rem;
        .mdc-button__label {
            @apply absolute w-full h-full opacity-0;
        }
        &.is-lg {
            @apply p-0 w-42 h-42 min-w-42 leading-none;
        }

        &.is-sm {
            @apply p-0 w-28 h-28 min-w-28 leading-none;
        }
    }

    //button mixins: button sizes, colors, states, etc. used for both text & icon buttons
    .button,
    .button.mat-mdc-button {
        @apply text-gray-dark dark:text-gray-light m-0 no-underline rounded transition duration-150;
        &:not(.icon-button) {
            @apply h-34 text-sm font-bold uppercase px-15;
            line-height: 3.4rem;

            &.is-xl {
                @apply px-30;
                height: 4.2rem;
                line-height: 4rem;
            }

            &.is-lg {
                @apply px-30 h-42;
                line-height: 4.2rem;
            }

            &.is-sm {
                @apply text-xs px-10 h-28;
                line-height: 2.6rem;
            }
        }

        &[disabled],
        &.disabled,
        :disabled {
            @apply bg-gray-light opacity-50 cursor-not-allowed;
        }

        &:not([disabled]):not(.disabled):not(:disabled) {
            @apply cursor-pointer;

            &:focus {
                &:not(.mat-mdc-menu-item):not(.is-tab) {
                    @apply ring;
                }
            }
        }

        &.is-primary {
            &:not([disabled]):not(.disabled) {
                &:not(.is-inverted) {
                    @apply bg-blue-dark dark:bg-blue;
                    @apply text-white;

                    &:hover {
                        @apply bg-blue dark:hover:bg-blue-light;
                    }
                }

                &.is-outlined {
                    @apply bg-transparent;
                    @apply text-blue-dark dark:text-blue-light;
                    @apply border;
                    @apply border-solid;
                    @apply border-blue-dark dark:border-blue-light;

                    &:hover {
                        @apply text-blue dark:text-blue-lighter;
                        @apply border-blue dark:border-blue-lighter;
                        @apply bg-blue-lighter dark:bg-blue-lighter;
                        @apply bg-opacity-25 dark:bg-opacity-10;
                    }
                }

                &.is-inverted {
                    @apply bg-transparent;
                    @apply text-blue-dark dark:text-blue-light;

                    &:hover {
                        @apply text-blue dark:text-blue-lighter;
                        @apply bg-blue-lighter dark:bg-blue-lighter;
                        @apply bg-opacity-25 dark:bg-opacity-10;
                    }
                }
            }
        }

        &.is-alert {
            &:not([disabled]):not(.disabled) {
                &:not(.is-inverted) {
                    @apply bg-orange;
                    @apply text-white;

                    &:hover {
                        @apply bg-orange;
                    }
                }

                &.is-outlined {
                    @apply bg-transparent;
                    @apply text-orange;
                    @apply border;
                    @apply border-solid;
                    @apply border-orange;

                    &:hover {
                        @apply text-orange-dark;
                        @apply border-orange-dark;
                        @apply bg-orange-lighter;
                        @apply bg-opacity-25;
                    }
                }

                &.is-inverted {
                    @apply bg-transparent;
                    @apply text-orange;

                    &:hover {
                        @apply text-orange-dark;
                        @apply bg-orange-lighter;
                        @apply bg-opacity-25;
                    }
                }
            }
        }

        &.is-warning {
            &:not([disabled]):not(.disabled) {
                &:not(.is-inverted) {
                    @apply bg-red;
                    @apply text-white;

                    &:hover {
                        @apply bg-red;
                    }
                }

                &.is-outlined {
                    @apply bg-transparent;
                    @apply text-red;
                    @apply border;
                    @apply border-solid;
                    @apply border-red;

                    &:hover {
                        @apply text-red-dark;
                        @apply border-red-dark;
                        @apply bg-red-lighter;
                        @apply bg-opacity-25;
                    }
                }

                &.is-inverted {
                    @apply bg-transparent;
                    @apply text-red;

                    &:hover {
                        @apply text-red-dark;
                        @apply bg-red-lighter;
                        @apply bg-opacity-25;
                    }
                }
            }
        }

        &.is-grey-dark {
            &.is-inverted {
                &:not([disabled]):not(.disabled) {
                    @apply text-gray-dark;
                    @apply bg-transparent;

                    &:hover {
                        @apply text-gray-darker;
                        @apply bg-blue-lightest;
                        @apply bg-opacity-50;
                    }
                }
            }
        }

        &.is-white {
            &:not([disabled]):not(.disabled) {
                &:not(.is-inverted) {
                    @apply text-gray-dark;
                    @apply bg-white;

                    &:hover {
                        @apply bg-gray-lighter;
                    }
                }

                &.is-inverted {
                    @apply text-white;
                    @apply bg-transparent;

                    &:hover {
                        @apply bg-blue-lightest;
                        @apply bg-opacity-25;
                    }
                }
            }
        }

        &.is-success {
            &:not([disabled]):not(.disabled) {
                &:not(.is-inverted) {
                    @apply text-white;
                    @apply bg-green;

                    &:hover {
                        @apply bg-green-dark;
                    }
                }

                &.is-outlined {
                    @apply bg-transparent;
                    @apply text-green;
                    @apply border;
                    @apply border-solid;
                    @apply border-green;

                    &:hover {
                        @apply text-green-dark;
                        @apply border-green-dark;
                        @apply bg-green-lighter;
                        @apply bg-opacity-25;
                    }
                }

                &.is-inverted {
                    @apply text-green;
                    @apply bg-transparent;

                    &:hover {
                        @apply text-green-dark;
                        @apply bg-green-lightest;
                    }
                }
            }
        }

        &.is-tab {
            @apply rounded-b-none border-b transition-colors duration-150;

            &:not(.active) {
                @apply border-transparent;
            }

            &.active {
                @apply text-blue-dark border-blue-dark;
            }
        }
    }

    .input,
    .select,
    .textarea {
        @apply font-medium rounded transition duration-150 outline-none no-underline tracking-wide bg-none border border-solid bg-white dark:bg-gray-darker min-w-96 leading-normal text-base m-0;
        text-shadow: none;

        &:not(mat-select) {
            @apply px-5 py-0;
        }

        &:not(textarea) {
            @apply h-34;
        }

        &:not([disabled]):not(.disabled):not(.mat-select-disabled):not(:disabled) {
            @apply text-gray-dark border border-solid border-gray-light dark:text-white dark:border-gray-dark;

            &:focus {
                @apply ring;
            }

            &.is-inverted {
                @apply border-transparent dark:bg-gray-darker;
            }

            &.ng-touched.ng-invalid {
                @apply bg-red-lighter bg-opacity-25 border-red;
            }
        }

        &[disabled],
        &.disabled,
        &:disabled,
        &.mat-select-disabled {
            @apply bg-gray-light border-gray text-gray-dark opacity-50 bg-opacity-50 cursor-not-allowed;
        }

        &.is-lg {
            @apply h-42;
        }

        &.is-sm {
            @apply h-28 text-sm;
        }
    }

    .flip-horizontal {
        transform: scaleX(-1);
    }

    .label {
        @apply p-0;
        @apply font-medium;
        @apply text-sm;
        @apply text-gray;
        @apply leading-snug;
        @apply whitespace-normal;
        @apply text-left;
        @apply inline-block;

        &.is-xl {
            @apply text-lg;
        }

        &.is-lg {
            @apply text-base;
        }

        &.is-md {
            @apply text-sm;
        }

        &.is-sm {
            @apply text-xs;
        }

        &.is-required {
            @apply text-red;

            &::after {
                content: "*";
                @apply absolute text-red ml-5 top-5;
            }
        }
    }
}

::-webkit-scrollbar {
    height: 1.2rem;
    width: 1.2rem;
}

::-webkit-scrollbar-thumb {
    @apply bg-gray-light;
    border-radius: 9999px;
}

.loader-wrap {
    height: 100%;
    width: 100%;
    display: flex;
}

.loader-main {
    border: 12px solid #c8d8e3;
    border-radius: 50%;
    border-top: 12px solid;
    @apply text-blue-900 animate-spin;
    width: 96px;
    height: 96px;
    // max-width: 40%;
    margin: auto;

    &.is-blue {
        @apply text-blue;
    }
}

.loader-sm {
    border: 4px solid #c8d8e3;
    border-radius: 50%;
    border-top: 4px solid;
    @apply text-blue-900 animate-spin;
    width: 30px;
    height: 30px;
    // max-width: 70%; //seems redundant and creates warped spinners
    margin: auto;

    &.is-blue {
        @apply text-blue;
    }
}

.grayscale {
    filter: grayscale(1);
}

#preloader-section {
    $timer: 2.5s;
    $fig-red: #754545;

    .preloader {
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 500;
        text-align: center;
        overflow: hidden;
        opacity: 1;
        @include transition-multi(0.2s, cubic, 0s, transform, opacity);

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 0;
            height: 50%;
            right: 0;
            background: #fff;
            z-index: 1;
        }

        &:before {
            top: -22px;
        }

        &:after {
            bottom: -23px;
        }

        h1,
        h2 {
            text-transform: uppercase;
            font-weight: 400;
            /*font-family: Bebas Neue W01,Verdana,Helvetica,Arial,sans-serif;*/
            margin: 0;
            letter-spacing: 4px;
            position: relative;
            z-index: 3;
        }

        h2 {
            background-color: #fff;
            position: absolute;
            width: 100%;
            top: 50%;
            -webkit-transform: translate(0, calc(-50% + 41px));
            transform: translate(0, calc(-50% + 41px));
            font-size: 18px;
            color: $fig-red;
            opacity: 1;
            -webkit-transition:
                opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 1.2s,
                -webkit-transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
            transition:
                opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 1.2s,
                -webkit-transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
            transition:
                opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 1.2s,
                transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
            transition:
                opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 1.2s,
                transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s,
                -webkit-transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;

            &:first-child {
                -webkit-transition:
                    opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s,
                    -webkit-transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
                transition:
                    opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s,
                    -webkit-transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
                transition:
                    opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s,
                    transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
                transition:
                    opacity 0.25s cubic-bezier(0.23, 1, 0.32, 1) 0s,
                    transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s,
                    -webkit-transform 1.2s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
                -webkit-transform: translate(0, calc(-50% - 41px));
                transform: translate(0, calc(-50% - 41px));
            }

            &:last-child {
                z-index: 2;
            }

            &:before,
            &:after {
                content: "";
                position: absolute;
                height: 1px;
                background-color: #bdc4c9;
                top: 51%;
                -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.35, 0, 0.08, 1) 0.1s;
                transition: -webkit-transform 0.7s cubic-bezier(0.35, 0, 0.08, 1) 0.1s;
                transition: transform 0.7s cubic-bezier(0.35, 0, 0.08, 1) 0.1s;
                transition:
                    transform 0.7s cubic-bezier(0.35, 0, 0.08, 1) 0.1s,
                    -webkit-transform 0.7s cubic-bezier(0.35, 0, 0.08, 1) 0.1s;
            }

            &:before {
                left: 0;
                right: 50%;
                -webkit-transform-origin: right;
                transform-origin: right;
            }

            &:after {
                right: 0;
                left: 50%;
                -webkit-transform-origin: left;
                transform-origin: left;
            }

            span {
                background: #fff;
                padding: 2px 15px;
                position: relative;
                z-index: 1;
            }
        }

        .wrapper-container {
            height: 100vh;
            position: relative;
            width: 274px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;

            &:before,
            &:after {
                content: "";
                position: absolute;
                height: 100%;
                width: 100%;
                background: #fff;
            }

            &:before {
                left: -100%;
            }

            &:after {
                left: 100%;
            }

            .preloader-wrapper {
                .preloader-title {
                    position: absolute;
                    width: 100%;
                    height: 46px;
                    top: 50%;
                    -webkit-transform: translate(0, -50%);
                    transform: translate(0, -50%);
                    overflow: hidden;
                    background-color: rgba(0, 0, 0, 0.3);

                    &:after,
                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        /*transform: translateY(0);*/
                        background: #fff;
                        -webkit-transition: -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
                        transition: -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
                        transition: transform 0.7s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
                        transition:
                            transform 0.7s cubic-bezier(0.23, 1, 0.32, 1) 1.2s,
                            -webkit-transform 0.7s cubic-bezier(0.23, 1, 0.32, 1) 1.2s;
                    }

                    &:before {
                        top: 0;
                        -webkit-transform: translate(0, -100%);
                        transform: translate(0, -100%);
                    }

                    &:after {
                        bottom: 0;
                        -webkit-transform: translate(0, 100%);
                        transform: translate(0, 100%);
                    }

                    img {
                        /*@include animate(zoomIn);
                        max-width: 100%;*/
                        width: 100%;
                        height: 100%;
                        fill: #fff;
                    }
                }
            }
        }

        &:not(.active) {
            transform: translateY(-100vh);
            opacity: 0;

            .preloader-title,
            h1,
            h2,
            h2:after,
            h2:before {
                -webkit-transition-delay: 0s;
                transition-delay: 0s;
                -webkit-transition-duration: 0s;
                transition-duration: 0s;
            }

            .preloader-title {
                &:after,
                &:before {
                    -webkit-transform: translate(0, 0);
                    transform: translate(0, 0);
                }
            }

            h1 {
                background-position: 0 0;
            }

            h2 {
                opacity: 0;
                -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);

                &:after,
                &:before {
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                }
            }
        }

        &.exit {
            -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
            opacity: 0;
            @include transition-multi(0.6s, cubic, 0s, transform, -webkit-transform, opacity);
        }
    }

    @media (min-width: 600px) {
        .preloader {
            &:before {
                top: -23px;
            }

            &:after {
                bottom: -23px;
            }

            h2 {
                -webkit-transform: translateY(calc(-50% + 100px));
                transform: translateY(calc(-50% + 100px));
            }

            .wrapper-container {
                max-width: 720px;
                width: 770px;
                margin-left: auto;
                margin-right: auto;

                .preloader-wrapper {
                    .preloader-title {
                        height: 130px;
                        /*&:before,
                        &:after {
                            -webkit-transform: translateY(0);
                            transform: translateY(0);
                        }*/
                    }
                }
            }

            &:not(.active) {
                .preloader-title {
                    height: 130px !important;

                    &:before,
                    &:after {
                        -webkit-transform: translateY(0);
                        transform: translateY(0) !important;
                    }
                }
            }

            &.active {
                &:before {
                    top: -64px;
                }

                &:after {
                    bottom: -64px;
                }
                /*-webkit-transform: translateY(calc(-50% + 100px));
                transform: translateY(calc(-50% + 100px));*/
                h2 {
                    &:first-child {
                        -webkit-transform: translateY(calc(-50% - 100px));
                        transform: translateY(calc(-50% - 100px));
                    }
                }
            }
        }
    }
}

.grayscale {
    filter: grayscale(1);
}

.grayscale-10 {
    filter: grayscale(10%);
}

.grayscale-40 {
    filter: grayscale(40%);
}

.grayscale-50 {
    filter: grayscale(50%);
}

.grayscale-60 {
    filter: grayscale(60%);
}

.grayscale-75 {
    filter: grayscale(75%);
}

@layer components {
    .required-label {
        @apply relative;

        &::after {
            content: "*";
            @apply absolute text-red ml-5 top-0 leading-tight text-xl;
        }
    }
}

mat-option {
    .mdc-list-item__primary-text {
        @apply w-full;
    }
    &.custom-mat-option {
        @apply items-center text-gray-dark px-0 rounded;
        height: unset !important;
        line-height: unset !important;

        .mat-option-text {
            @apply flex justify-start text-gray-dark flex-grow h-full;

            a {
                @apply flex flex-grow;
            }
            app-search-result {
                @apply p-10 w-full;
            }
        }
    }
}

cdk-virtual-scroll-viewport.rtl {
    direction: rtl;
}

.cdk-virtual-scroll-content-wrapper {
    direction: ltr;
}

app-onboarding {
    @apply w-full;
    @apply h-full;
    @apply fixed;
    @apply z-10;
    @apply flex;
    @apply bg-gray-lightest;
    @apply text-gray-dark;
    @apply leading-snug;
    @apply font-body;
    @apply text-base;

    #onboardingMainNav {
        animation: 0.7s 0.05s backwards fadeIn;
    }

    .onboarding-nav-item {
        // &.is-completed {
        //     &:not(.is-active) {
        //         .onboarding-checkbox {
        //             @apply bg-blue-dark border-blue-dark;
        //         }

        //         .onboarding-nav-label {
        //             @apply text-blue-dark;
        //         }
        //     }
        // }

        &.is-active {
            .onboarding-checkbox {
                @apply bg-blue-dark border-blue-dark;
                // &:not(:last-child) {
                //     &:after {
                //         @apply bg-blue-dark;
                //     }
                // }
            }

            .onboarding-nav-label {
                @apply text-blue-dark;
            }
        }

        &:not(:last-child) {
            .onboarding-checkbox {
                &:after {
                    @apply absolute bg-gray-lighter w-px h-full;
                    content: "";
                    margin-top: 19px;
                    margin-left: 9px;
                }
            }
        }

        .onboarding-checkbox {
            @apply transition duration-500 border-gray-light;

            &:after {
                @apply transition duration-500 delay-200;
            }
        }
    }

    .locked {
        cursor: default !important;
        pointer-events: none;
    }
    app-welcome,
    app-terms-of-use,
    app-agreement,
    app-platform-configuration,
    app-carrier-setup,
    app-training,
    app-portal-preview {
        @apply block;
        @apply h-full;
        @apply w-full;
        animation: 0.7s 0.05s backwards fadeIn;
        @apply overflow-hidden flex flex-col;

        & > * {
            @apply flex-grow;
        }
    }

    app-agreement {
        section {
            #docIsSigned,
            #docError {
                animation: 0.7s 0.05s backwards fadeIn;
            }
        }
    }

    #wistia_img {
        @apply transition-all;
        @apply ease-in-out;
        @apply scale-100;
        @apply duration-200;

        &:hover {
            transform: scale(1.05);
        }
    }

    .progress-block {
        animation: 0.7s 0s backwards fadeIn;

        &.is-completed {
            .progress {
                .md-bar {
                    @apply bg-green-dark;
                }
            }
        }
        .progress-bar {
            &.translate-0 {
                transform: translateX(calc(-100% * (4 / 4)));
            }
            &.translate-1 {
                transform: translateX(calc(-100% * (3 / 4)));
            }
            &.translate-2 {
                transform: translateX(calc(-100% * (2 / 4)));
            }
            &.translate-3 {
                transform: translateX(calc(-100% * (1 / 4)));
            }
            &.translate-4 {
                transform: translateX(calc(-100% * (0 / 4)));
            }
        }
    }

    md-progress-linear.md-default-theme,
    md-progress-linear {
        .md-container {
            @apply bg-blue-lighter;
            @apply bg-opacity-25;
        }

        .md-bar {
            @apply bg-blue;
        }
    }
}

app-portal {
    @apply w-screen h-screen relative z-10 flex bg-gray-lightest text-gray-dark dark:bg-gray-darkest dark:text-gray-lighter leading-normal font-body text-base;

    .portal-body {
        > * {
            &:not(router-outlet) {
                @apply flex flex-col;
                // &:not(app-product-rd) {
                //     @apply p-10;

                //     @media (min-width: theme("screens.md")) {
                //         @apply p-20;
                //     }
                // }
            }
        }
    }

    // To allow subnav preview to show on hover
    .portal-sidenav-container {
        > .mat-drawer-inner-container {
            @apply overflow-visible;
        }
    }

    .portal-nav {
        .portal-subnav {
            &.subnav-absolute {
                @apply absolute top-0 bottom-0 right-0 bg-white dark:bg-gray-darkest py-30 transition duration-200 transform translate-x-full left-48;

                .portal-nav-item:not(.is-active):not(:hover) {
                    &.lvl-3 {
                        @apply text-gray dark:text-white;
                    }
                }
            }
            &.is-active {
                @apply translate-x-0;
            }
            .portal-nav-item {
                @apply h-36;

                &.is-active {
                    @apply bg-transparent;
                }
            }
        }

        .portal-nav-item {
            @apply relative h-48 font-bold transition duration-150 flex items-center pl-15 cursor-pointer outline-none no-underline dark:text-white dark:text-opacity-75;

            &.is-active.no-bar {
                &::after {
                    @apply hidden;
                }
            }

            &::after {
                content: "";
                @apply absolute;
                @apply right-0;
                @apply top-0;
                @apply w-4;
                @apply h-full;
                @apply bg-transparent;
            }

            &:hover,
            &.is-active {
                @apply text-blue-dark dark:text-blue-light;
                @apply no-underline;
            }

            &.is-active {
                @apply bg-blue-lighter;
                @apply bg-opacity-25;

                &::after {
                    @apply bg-blue-dark dark:bg-blue-light;
                }
            }
        }

        .portal-nav-label {
            @apply text-xs uppercase tracking-wide;
        }
    }
}

app-snapshot {
    @apply w-full h-full flex flex-col;
}

//moving top-lvl padding here
app-dashboard-container,
app-disability,
app-financial-planning-overview,
app-fixed-indexed-annuities,
app-life-insurance,
app-medicare,
app-order-complete,
app-rilas,
app-signature-complete,
app-structured-investments,
app-toolbox,
app-variable-annuities,
app-page-not-found {
    @apply h-full flex flex-col p-20 md:p-30;
}

app-dashboard-container,
app-resource-details {
    @apply w-full;
}

app-business,
app-carriers-training,
app-fig-platform,
app-life-insurance {
    @apply h-full flex flex-col;
}

app-lifecycle-snapshot{
    @apply h-full w-full flex flex-col overflow-auto;
}

app-business {
    app-structured-lifecycle,
    app-structured-holdings,
    app-structured-indication-of-interest {
        @apply h-full flex;
    }
}

app-dashboard-container {
    background: url("./assets/images/wave-lg-half.png");
    @apply bg-center bg-no-repeat bg-cover;
}

app-explore-details {
    a {
        @apply text-blue-dark font-semibold hover:text-blue-900;
    }
    p {
        @apply mb-10;
    }
    ul {
        @apply list-disc list-inside text-base mx-20 my-5;
    }
    ol {
        @apply list-decimal list-inside text-base mx-20 my-5;
    }
}

app-business {
    app-pending-policy-list,
    app-lifecycle-policy-list,
    app-informal-policy-details,
    app-policy-details,
    app-policy-details-v2 {
        @apply h-full w-full flex flex-col;
    }
}

app-carriers-training-overview {
    @apply h-full flex flex-col;
}

app-policy-list {
    @apply h-full flex;
}

app-delay-redirect {
    @apply h-full;
}

app-illustration,
app-marketplace-details,
app-structured-investments-builder {
    @apply flex flex-col h-full relative;
}

app-application-history,
app-workflow {
    @apply min-h-full h-full flex flex-col flex-grow;
}

app-workflow {
    app-prepare-e-application {
        @apply flex flex-row h-full flex-wrap flex-grow items-stretch overflow-hidden;
    }
}

app-execute {
    @apply flex flex-col flex-grow;
}

app-embedded-application {
    @apply flex flex-col flex-grow;

    .is-expanded {
        @apply w-full h-full absolute top-0 right-0;

        iframe {
            @apply h-full;
        }
    }
}

app-log-out {
    @apply h-full w-full fixed flex flex-col flex-grow overflow-auto bg-gray-lightest;
}

app-sso {
    @apply h-full w-full fixed flex flex-col flex-grow overflow-auto justify-center items-center bg-gray-lightest;
}

app-lifecycle-policies-list-page {
    @apply flex h-full w-full;
}

app-checkbox-filter {
    @apply flex h-full w-full;
}
